import { cloneDeep } from 'lodash';

const INIT_USER_INFO_FORM = {
  sei: {
    is_required: true,
  },
  mei: {
    is_required: true,
  },
  kanaSei: {
    is_required: true,
  },
  kanaMei: {
    is_required: true,
  },
  barth: {
    is_required: true,
  },
  gender: {
    is_required: false,
  },
  position: {
    is_required: true,
  },
  degree: {
    is_required: true,
  },
  postalCode: {
    is_required: true,
  },
  address: {
    is_required: true,
  },
  institutionName: {
    is_required: true,
  },
  institutionName2: {
    is_required: false,
  },
  institutionName3: {
    is_required: false,
  },
  institutionPostalCode: {
    is_required: true,
  },
  institutionAddress: {
    is_required: true,
  },
  institutionStation: {
    is_required: true,
  },
  institutionTel: {
    is_required: true,
  },
  institutionFax: {
    is_required: false,
  },
  alternativeName: {
    is_required: true,
  },
  alternativeTel: {
    is_required: true,
  },
  alternativeInstitution: {
    is_required: true,
  },
};

export default {
  namespaced: true,
  state: {
    form: cloneDeep(INIT_USER_INFO_FORM),
  },
  mutations: {
  },
  actions: {
  },
};
